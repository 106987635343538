nav.menu-wrapper {
    position: absolute;
    display: flex;
    top: var(--header-height);
    z-index: calc(var(--header-z-index) + 2);
    background-color: #333;
    font-weight: 400;
    font-size: 1.6rem;
    color: #f2f2f2;
    height: ~"calc(100vh - var(--header-height))";
    /*bottom: 0;*/
    overflow-y: auto;
    right: -321px;
    transition-property: opacity, right;
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;
    width: 0;
    opacity: 0;
    justify-content: space-between;
    flex-direction: column;

    &.open {
        // width: 320px;
        opacity: 1;
        right: 0;
        width: 320px;
    }

    .menu-account-link {
        .logo-rounded {
            border: 1px solid #f2f2f2;
            margin-right: 14px;
            margin-top: 17px;
            float: left;
        }
    }

    .disclaimer {
        flex-grow: 1;
        font-size: 13px;
        opacity: 0.5;
        position: relative;
        overflow: hidden;

        img {
            margin: 0 0.4em;
            width: 1.5em;
        }

        &-wrapper {
            position: absolute;
            bottom: 0;
            padding: 20px;
        }
    }
}

.navigation-header-spacer {
    .o-layout--desktop & {
        display: none;
    }
    height: var(--header-height);
}

#navigation-header {
    .o-layout--desktop & {
        display: none;
    }
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) 0
        env(safe-area-inset-left);

    .navigation-header-wrapper {
        height: var(--header-height);
        background-color: var(--header-background-color);
        color: var(--header-text-color);
        position: relative;

        .header-title {
            vertical-align: middle;
            text-align: center;
            font-weight: 700;
            font-size: @size-H3;
            color: var(--header-text-color);
            white-space: nowrap;
            overflow: hidden;
            line-height: var(--header-height);
        }
    }
}
