.c-active-period-indicator {
    display: block;
    height: 65px;
    position: absolute;
    right: 80px;
    transition: opacity 0.2s ease-out;

    &--notactive {
        opacity: 1;
    }

    &--active {
        opacity: 0;
    }

    svg {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
        width: 18px;
        display: block;
        margin: 0;
        padding: 0;

        path {
            fill: #fff;
            fill-opacity: 0.5;
        }
    }
}