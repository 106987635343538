@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?40892985');
  src: url('../font/fontello.eot?40892985#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?40892985') format('woff2'),
       url('../font/fontello.woff?40892985') format('woff'),
       url('../font/fontello.ttf?40892985') format('truetype'),
       url('../font/fontello.svg?40892985#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?40892985#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-email:before { content: '\2600'; } /* '☀' */
.icon-globe:before { content: '\e410'; } /* '' */
.icon-right-open-big:before { content: '\e411'; } /* '' */
.icon-left-open-big:before { content: '\e412'; } /* '' */
.icon-down-open-big:before { content: '\e413'; } /* '' */
.icon-up-open-big:before { content: '\e414'; } /* '' */
.icon-up-open-mini:before { content: '\e415'; } /* '' */
.icon-right-open-mini:before { content: '\e416'; } /* '' */
.icon-left-open-mini:before { content: '\e417'; } /* '' */
.icon-down-open-mini:before { content: '\e418'; } /* '' */
.icon-up-open:before { content: '\e419'; } /* '' */
.icon-attention:before { content: '\e800'; } /* '' */
.icon-phone:before { content: '\e801'; } /* '' */
.icon-home:before { content: '\e802'; } /* '' */
.icon-sun:before { content: '\e803'; } /* '' */
.icon-facebook:before { content: '\e804'; } /* '' */
.icon-bog:before { content: '\e805'; } /* '' */
.icon-maps:before { content: '\e806'; } /* '' */
.icon-linkedin:before { content: '\e807'; } /* '' */
.icon-house:before { content: '\e808'; } /* '' */
.icon-page:before { content: '\e809'; } /* '' */
.icon-twitter:before { content: '\e80a'; } /* '' */
.icon-shop:before { content: '\e80b'; } /* '' */
.icon-ok:before { content: '\e80c'; } /* '' */
.icon-tag:before { content: '\e80d'; } /* '' */
.icon-menu:before { content: '\e80e'; } /* '' */
.icon-search:before { content: '\e80f'; } /* '' */
.icon-agenda:before { content: '\e810'; } /* '' */
.icon-photo:before { content: '\e811'; } /* '' */
.icon-vacancy:before { content: '\e812'; } /* '' */
.icon-news:before { content: '\e813'; } /* '' */
.icon-companyphoto:before { content: '\e814'; } /* '' */
.icon-ccw:before { content: '\e815'; } /* '' */
.icon-openinghours:before { content: '\e816'; } /* '' */
.icon-dynamic:before { content: '\e817'; } /* '' */
.icon-custom:before { content: '\e818'; } /* '' */
.icon-cw:before { content: '\e819'; } /* '' */
.icon-moon:before { content: '\e81a'; } /* '' */
.icon-about:before { content: '\e81b'; } /* '' */
.icon-trash-empty:before { content: '\e81c'; } /* '' */
.icon-dot-3:before { content: '\e81d'; } /* '' */
.icon-topic:before { content: '\e81e'; } /* '' */
.icon-cancel:before { content: '\e81f'; } /* '' */
.icon-help:before { content: '\e820'; } /* '' */
.icon-checkmark:before { content: '\e821'; } /* '' */
.icon-minus:before { content: '\e822'; } /* '' */
.icon-back:before { content: '\e832'; } /* '' */
.icon-bell:before { content: '\e840'; } /* '' */
.icon-eye:before { content: '\e900'; } /* '' */
.icon-info-circled:before { content: '\f085'; } /* '' */
.icon-download-cloud:before { content: '\f0ed'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-language:before { content: '\f1ab'; } /* '' */
.icon-cc-stripe:before { content: '\f1f5'; } /* '' */
.icon-pinterest:before { content: '\f231'; } /* '' */
.icon-question-circle-o:before { content: '\f29c'; } /* '' */
