.c-tabs {
    @background: #f9f9f9;
    @color: #222;
    @background-active: @mp-blue;
    @color-active: #fff;

    display: flex;
    .ft-open-sans();
    font-size: 1.3rem;
    max-width: 400px;

    &--inline {
        display: inline-flex;
    }

    &__item {
        display: block;
        padding: 10px 5px;
        height: auto;
        line-height: normal;
        font-size: inherit;
        .wt-semi();
        min-width: 50px;
        flex-grow: 1;
        flex-basis: 0;
        background: @background;
        border-radius: @border-radius;
        color: @color;
        text-align: center;
        cursor: pointer;
        transition-property: color, background-color;
        transition-duration: 0.1s;
        transition-timing-function: ease-in;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
            font-size: 1.4rem;
            line-height: 25px;
        }

        &--icon {
            height: 25px;
            width: 29px;
            display: inline-block;
            min-width: 35px;
            padding: 10px 0;

            span {
                display: inline-block;
                &.hide{
                    display: none;   
                }
            }
        }

        &--active {
            color: @color-active !important; // important as we have a rule that sets the color to :visited links
            background-color: @background-active;
        }

        &--disabled {
            background-color: fadeout(@background, 25%);
            color: fadeout(@color, 25%);
            cursor: not-allowed;
        }

        &--delete {
            color: @background !important; // important as we have a rule that sets the color to :visited links
            background-color: #fd3d30;
        }

        &--dark {
            color: @color !important; // important as we have a rule that sets the color to :visited links
            background-color: #f2f2f2;

            &.active {
                color: #fff !important; // important as we have a rule that sets the color to :visited links
                background-color: #007ebc;
            }

            &.toggleactive {
                color: #fff !important; // important as we have a rule that sets the color to :visited links
                background-color: #50A100;
            }
        }

        & + .c-tabs__item {
            margin-left: 6px;
        }
    }

    &__animate {
        background-color: #ccc;
        padding: 5px;
        border-radius: @border-radius;
        position: relative;
        box-sizing: border-box;

        .c-tabs__item {
            background-color: #ccc;
            color: #fff;
            border-radius: 5px;

            &--active {
                color: @mp-blue !important; // important as we have a rule that sets the color to :visited links
                background-color: darken(@background, 0.5%);
            }
        }
        /*.c-tabs__slider {
            position: absolute;
            z-index: 1;
            top: 5;
            left: 5;
            bottom: 5;
            width: 50%;
            display: block;
            border-radius: 5px 0 0 5px;
            background-color: #FFF;
            transition: all 200ms ease;
            z-index: 1;
            margin: 5px;
            box-sizing: border-box;

            &.right {
                left: 50%;
                border-radius: 0 5px 5px 0;
                transition: all 200ms ease;
            }
        }*/
    }

    @supports

    (display: grid) {
        display: grid;
        grid-template-rows: 1;
        grid-template-columns: repeat( auto-fit, minmax(8rem, 1fr) );
        grid-auto-rows: 1fr;
        grid-auto-columns: 1fr;
        grid-gap: 5px;

        &--inline {
            display: inline-grid;
        }

        &__item + .c-tabs__item {
            margin-left: 0;
        }

        &--icons {
            display: flex;
            gap: 0;

            .c-tabs__item--icon + .c-tabs__item--icon {
                margin-left: 6px;
            }
        }
    }
}

.o-layout--desktop {
    .c-tabs {
        max-width: 700px;

        &__item--icon {
            min-width: 50px;
            margin-left: 6px;


            &.c-tabs__item--dark:hover {
                background-color: #e4e4e4;
            }

            &.c-tabs__item--dark.toggleactive:hover {
                background-color: darken(#50A100, 2%);
            }

            &.c-tabs__item--delete:hover {
                background-color: #e3372b;
            }
        }
    }
}
