// see: https://github.com/euvl/vue-js-modal/issues/674#issuecomment-844301259
.vm--modal {
    height: auto !important;
}

body.screenshot {
    #header {
        top: 32px;
        display: block;
    }

    .c-breadcrumb-nav {
        top: 97px;
    }

    #content {
        margin-top: 147px;
    }

    .screenshot-spacer {
        height: 32px;
        background-color: #007ebc;
        display: block;
        z-index: 100;

        &.lightheader {
            background-color: #f7f7f7;
        }
    }

    &.o-layout--desktop {
        .screenshot-spacer {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
        }
    }

    &.o-layout--mobile {
        #header {
            top: 0px;
        }

        &.navigation-header {
            .screenshot-spacer {
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
            }

            #navigation-header {
                top: 32px;
            }

            .navigation-header-spacer {
                height: 97px;
            }

            .back-button-wrapper {
                top: 32px;
            }

            .submit-wrapper {
                top: 47px;
            }
        }
    }
}

.pswp {
    &__button--download {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff !important;
        font-size: 2rem;
    }
}

[x-cloak] {
    display: none !important;
}
