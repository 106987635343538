
.v-select--transparent .vs__search::placeholder,
.v-select--transparent .vs__dropdown-toggle,
.v-select--transparent .vs__dropdown-menu {
    background: #dfe5fb;
    border: none;
    color: #394066;
    text-transform: lowercase;
    font-variant: small-caps;
}

.v-select--transparent .vs__clear,
.v-select--transparent .vs__open-indicator {
    fill: #394066;
}
