.button-variant(@color; @background; @border) {
    color: @color;
    background-color: @background;
    border-color: @border !important;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
    transition-property: all;

    a& {
        color: @color !important;
    }

    &[disabled]:not(.c-btn--transparent),
    &.disabled:not(.c-btn--transparent) {
        background-color: fade(@background, 60%);
        border-color: fade(@border, 60%) !important;
    }

    &:focus:not(&.disabled):not(&[disabled]),
    &.focus:not(&.disabled):not(&[disabled]),
    &:hover:not(&.disabled):not(&[disabled]) {
        background-color: darken(@background, 10%);
        border-color: darken(@border, 10%);
    }


    &:active:not(&.disabled):not(&[disabled]),
    &.active:not(&.disabled):not(&[disabled]) {
        background-color: darken(@background, 10%);
        border-color: darken(@border, 10%);

        &:hover,
        &:focus,
        &.focus {
            background-color: darken(@background, 17%);
            border-color: darken(@border, 17%);
        }
    }
}

.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height: normal; @border-radius : 6px) {
    padding: @padding-vertical @padding-horizontal;
    font-size: @font-size;
    line-height: @line-height;
    border-radius: @border-radius;
}
