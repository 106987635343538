.c-app-header {
    --logo-width: 100px;
    --logo-height: 18px;
    background-color: var(--color-blue);
    position: fixed;
    padding: 0 15px;
    left: 0;
    right: 0;
    top: 0;
    user-select: none;
    height: var(--header-height);
    margin: 0;
    z-index: var(--header-z-index);
    color: var(--header-text-color);
    // the header is fixed on desktop
    .o-layout--mobile & {
        position: relative;
    }

    &::before {
        content: "";
        position: fixed;
        background-color: rgba(0,0,0,0.8);
        top: var(--header-height);
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        z-index: var(--header-z-index);
        opacity: 0;
        transition: opacity 0.2s;
    }

    &.flyout-open::before {
        opacity: 1;
        pointer-events: all;
    }


    &--logo-wrapper {
        --scale: 1;
        display: inline-block;
        left: 50%;
        top: 50%;
        position: absolute;
        transform: translateX(-50%) translateY(-50%) scale(var(--scale));
        transition: transform .1s;
        width: var(--logo-width);
        height: var(--logo-height);
        overflow: hidden;

        &:active {
            --scale: 0.95;
        }
    }

    &--logo {
        display: block;
        background-image: url("../../../Assets/Images/ziber_logo_white_landscape.svg");
        background-repeat: no-repeat;
        background-position: center center;
        width: var(--logo-width);
        height: var(--logo-height);
        margin: 0;
    }

    .current-company {
        cursor: pointer;
        display: inline-block;
        position: absolute;
        left: 15px;
        display: flex;
        align-items: center;
        height: var(--header-height);

        &--name-address {
            white-space: nowrap;
            overflow: hidden;
            max-width: calc((100vw / 2) - 160px);
            display: flex;
            flex-direction: column;
            margin-left: 1.5em;

            @media screen and (max-width: 450px) {
                display: none;
            }

            &:hover, &:active {
                .current-company--name {
                    color: rgba(255,255,255,.8);
                }

                .current-company--address {
                    color: rgba(255,255,255,.85);
                }
            }
        }

        &--name {
            font-size: 1.6em;
            font-weight: 600;
        }

        &--address {
            font-size: 1.3em;
            font-weight: normal;
            color: rgba(255,255,255,.90);
        }

        &--logo {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            background-size: 40px 40px;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .flag {
            position: absolute;
            width: 16px;
            height: 16px;
            left: 28px;
            top: 35px;
            border-radius: 50%;
            overflow: hidden;
            object-fit: cover;
            filter: grayscale(30%);
            padding: 1px;
            background: white;
        }
    }
}
