.site-navigation-wrapper {
    display: flex;
    bottom: 15px;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: ~"calc(var(--header-z-index) - 3)";
    pointer-events: none;
}

#site-navigation {
    background: #FFFFFF;
    width: 100%;
    height: 64px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 64px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.20);
    max-width: var(--content-width);
    pointer-events: all;

    > div {
        width: 44px;
        height: 44px;
        margin: 0 10px;
        transition: transform .1s linear;

        &:active {
            transform: scale(0.9);
        }
    }
}

#site-navigation-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: background 0.2s ease-in-out;
    background: rgba(0,0,0,.2);
    display: none;
    z-index: 100;

    &.visible {
        display: block;
    }

    .navigation {
        position: fixed;
        left: 15px;
        right: 15px;
        bottom: 0;
        padding-bottom: 15px;
        text-align: left;
        font-size: 1.8rem;
        transition: -webkit-transform ease-out 0.25s;
        transition: transform ease-out 0.25s;
        transition: transform ease-out 0.25s, -webkit-transform ease-out 0.25s;

        &.is-open {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            z-index: 1;
        }

        > ul {
            margin: 0;
            padding: 0;
            overflow: hidden;

            li {
                display: flex;
                align-items: center;
                padding: 0 20px 0 22px;
                background: #FFF;
                color: #007EBC;
                height: 55px;
                font-weight: 600;
                transition: background .1s linear;

                &:first-of-type {
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                }

                &:not(:first-child) {
                    border-top: 1px solid #DBDBDB;
                }

                &:nth-last-of-type(2) {
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                }

                > .label {
                    flex-grow: 1;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    cursor: pointer;
                }

                .more, .add, .modify, .settings {
                    margin-left: 15px;
                    font-size: 0;

                    svg {
                        height: 32px;
                        width: 32px;
                    }
                }

                div.add {
                    cursor: auto;
                }

                &.cancel, &.back {
                    margin-top: 15px;
                    border-radius: 8px;
                    height: 55px;
                    line-height: 55px;
                    color: #555;
                    text-align: center;
                    cursor: pointer;
                    font-size: 1.6rem;
                    font-weight: 300;
                    display: block;
                }

                &.back .less {
                    display: none;
                }
            }

            &.add-menu {
                text-align: center;

                li {
                    display: block;
                    line-height: 60px;

                    a {
                        margin: 0;
                        display: block;
                    }
                }
            }
        }
    }
}

.o-layout--desktop {
    #site-navigation {
        .navigation-toggle {
            cursor: pointer;
        }

        height: 82px;
        border-radius: 15px;
        width: 270px;
        justify-content: center;
        box-shadow: 0px 15px 50px 0px rgba(0,0,0,0.5);

        .add {
            width: 62px;
            height: 62px;


            svg {
                width: 62px;
                height: 62px;
            }
        }
    }

    #site-navigation-container {

        .navigation {
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: center;
            bottom: 105px;
            left: unset;
            right: unset;
            padding-bottom: 0;
            border-radius: 15px;

            > ul {
                width: 270px;
                box-shadow: 0px 15px 50px 0px rgba(0,0,0,0.5);
                border-radius: 15px;
                background: white;
                padding: 8px 0;

                li {
                    height: 33px;
                    border-top: unset;
                    font-size: @size-larger;
                    padding: 0 10px 0 11px;
                    margin: 0 12px;
                    border-radius: unset;

                    .more, .add, .modify, .settings, .less {
                        margin-left: 10px;
                        font-size: 0;
                        opacity: 0;

                        svg {
                            height: 22px;
                            width: 22px;
                        }
                    }

                    &:hover {
                        color: @active-color;
                        background-color: @hover-grey;

                        .more, .add, .modify, .settings, .less {
                            opacity: 1;
                        }
                    }

                    a:not(.add):not(.modify), .website-menu {
                        line-height: 33px;
                        color: @font-color-darker;


                        &:hover {
                            color: @active-color;
                            background-color: @hover-grey;
                        }
                    }

                    span.label {
                        line-height: 33px;
                    }

                    span.less {
                        transform: rotateY(180deg);
                        transform-origin: center center;

                    }
              
                    &.cancel {
                        display: none;
                    }

                    &.back {
                        display: flex;
                        text-align: left;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
