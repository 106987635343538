.c-person-list-item {
    font-size: 1.3rem;
    flex-wrap: nowrap;
    display: flex;

    &__avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 10px;
        &--medium {
            width: 50px;
            height: 50px;
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: auto;
        min-width: 0;
    }

    &__name {
        .wt-semi();
        font-size: inherit;
    }

    &__relation {
        font-size: inherit;
        // color: @mp-blue;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
