.c-tag {
    display: inline-block;
    .wt-semi();
    font-size: 1em;
    padding: 0.3em 0.7em;
    background-color: #af7b96;
    color: #fff;
    border-radius: .3em;
    text-transform: lowercase;
    white-space: nowrap;
    line-height: 1.2em;
    font-weight: 600;
    font-family: @font-source-sans;
    align-self: center;

    &--sm {
        text-transform: none;
        color: #fff;
        border-radius: 4px;
        font-size: 1.1rem;
        padding: 0.3rem 1.1rem;
        max-width: 10rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    + .c-tag--sm {
        margin-left: 0.5rem;
    }

    &--positive {
        background-color: @button-green-color;
    }

    &--negative {
        background-color: @button-red-color;
    }

    &--limbo {
        background-color: @button-red-color;
    }

    &--grayscale, &--greyscale {
        background-color: @button-darkgrey-color;
    }

    &--magenta {
        background-color: @fiep-magenta;
    }

    &--grey {
        background-color:@grey-accent;
    }
}
