.o-title {
    .ft-poppins();
    font-weight: 600;
    font-size: @size-extra-large;
    .wt-semi();

    &--small {
        font-size: @size-medium;
        font-family: @font-family;        
        line-height: 20px;
    }

    &--medium {
        font-size: @size-larger;
        font-family: @alternate-title-font-family;
        .wt-semi();
        line-height: 20px;
    }

    &--modal {
        font-size: @size-medium;
        font-family: @font-family;
        .wt-semi();
        line-height: 20px;
    }
}
.o-data {
    font-size: @size-medium;
    font-family: @font-family;    
    .wt-regular();
    line-height: 20px;
}
