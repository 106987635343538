.c-company-selector-flyout {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    position: absolute;
    top: var(--header-height);
    left: 0;
    background-color: #007ebc;
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100vh;
    transform: translateX(-100%);
    transition: all 0.3s ease-out;
    width: auto;
    min-width: 300px;
    border-top: 1px solid #1f8dc4;
    z-index: var(--header-z-index);
    li {
        position: relative;
        border-bottom: 1px solid #1f8dc4;

        a {
            display: flex;
            align-items: center;
            height: 70px;
            gap: 10px;
            padding-inline: 15px;
        }

        .logo {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            background-size: 40px 40px;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .flag {
            top: 44px;
            position: absolute;
            width: 16px;
            height: 16px;
            left: 40px;
            border-radius: 999px;
            overflow: hidden;
            object-fit: cover;
            filter: grayscale(30%);
            padding: 1px;
            background: white;
        }

        &:hover {
            background-color: #0074ad;
        }

        span {
            font-size: 1.6em;
            font-weight: 600;
            .ft-open-sans();
        }

        &:last-of-type {
            span {
                margin-left: 65px;
            }
        }
    }

    &.open {
        transform: translateX(0);
    }
}
