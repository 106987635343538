#settings-menu {
    padding: 0 2em;
    background-color: #f2f2f2;

    .anchor {
        scroll-margin-top: var(--total-header-height);
    }

    .title {
        margin: 0 0 12px 0;
        font-size: 1.9rem;
        font-weight: 700;
        color: @font-color-darker;
        font-family: @title-font-family;
    }

    .settings-container:not(first-of-type) {
        margin-top: 26px;
    }

    .settings-container:last-of-type {
        margin-bottom: 50px;
    }

    .icon-container {
        padding: 0;
        opacity: 1;
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(auto-fit, 150px);
        // display: flex;
        // flex-flow: wrap;
        // align-content: flex-start;
        // justify-content: flex-start;
        height: max-content;
        margin: 12px 0 4px 0;
        user-select: none;

        svg {
            vector-effect: non-scaling-stroke;
            height: 32px;

            circle.background-fill, path.background-fill {
                fill: #fff;
            }
        }


        div.tile-container {
            display: grid;
            grid-template-rows: 3fr 2fr;
            grid-template-columns: 1fr;
            justify-items: center;
            padding: 8px;
            border-radius: 8px;
            background-color: white;
            cursor: pointer;
            min-width: 126px;
            height: 116px;
            font-size: 1.3rem;
            font-family: @font-family;
            color: @font-color-darker;
            font-weight: normal;
            transition: all 0.1s ease-in-out;
            transform-origin: center center;
            box-shadow: 0 5px 10px 3px rgba(0,0,0,0);

            &:hover {
                transform: scale(1.025) translateY(-5px);
                box-shadow: 0 5px 10px 3px rgba(0,0,0,0.1);
            }

            &:active, &:hover:active {
                transform: scale(0.975) translateY(0);
                box-shadow: 0 5px 10px 3px rgba(0,0,0,0.1);
            }

            &.setting-disabled {
                .tile-icon, .tile-title {
                    opacity: 0.5;
                }

                &:hover, &.hover {
                    span.tile-icon {
                        opacity: 0.5;
                    }
                }
            }



            .tile-icon {
                margin-top: 8px;
                align-self: center;
                opacity: 1;

                + .tile-title {
                    margin-top: 8px;
                    text-align: center;
                }
            }

            span.tile-title {
                outline: none;
                align-self: self-start;
            }
        }
    }
}
