.c-avatar-group {
    @size: 40px;
    @indent: 2rem;

    position: relative;

    &--item {
        font-size: 1.5rem;
    }

    &--block {
        &.c-avatar-group--count-1 {
            .c-avatar-group__item {
                width: 65px;
                height: 65px;
            }
        }

        &.c-avatar-group--count-2,
        &.c-avatar-group--count-3 {
            .c-avatar-group__item {
                position: absolute;
                left: 0;
                top: 0;
                width: @size;
                height: @size;
                border: 1px solid #fff;

                &:nth-of-type(2) {
                    left: auto;
                    top: auto;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }

    &--clamped .c-avatar-group__item--clamp-indicator {
        left: auto;
        top: auto;
        right: 10px;
        bottom: 0;
    }

    &--inline {
        .c-avatar-group__item {
            position: relative;
            display: inline-flex;
            vertical-align: top;
            left: auto !important;
            right: auto !important;
            width: @size;
            height: @size;
            border: 1px solid #fff;
            transform: translateX(0);

            &:nth-of-type(2) {
                transform: translateX(@indent * -1);
            }
            &:nth-of-type(3) {
                transform: translateX(@indent * -1 * 2);
            }
        }
    }

    &.c-avatar-group--count-2 {
        width: calc(@size * 2 - @indent);
    }
    &.c-avatar-group--count-3 {
        width: calc(@size * 3 - (@indent * 2));
    }

    &__item--clamp-indicator {
        color: #fff;
        background: #af7b96;
        font-size: 1.5rem;
    }
}
