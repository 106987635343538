.c-sub-header {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 20px;
    justify-content: space-between;
    align-items: center;
    font-size: 1.3rem;

    &__item {
        padding: 10px;

        &-left {
            padding-right: 0;
        }

        &-center {
            display: flex;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 10px 0;
        }

        &-right {
            padding-left: 0;
        }
    }

    .icon-dot-3 {
        font-size: 2rem;
    }

    .contentWrapper & {
        border-bottom: 1px solid #f2f2f2;
    }
}

.c-dropdown-nav {
    position: fixed;
    left: 0;
    bottom: 0;
    display: block;
    height: 0;
    top: 0;
    z-index: 1;
    color: @font-color-darker;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);

    &--open {
        height: auto;
        .c-dropdown-nav__items {
            transform: scaleY(1);
        }
    }

    &__items {
        position: fixed;
        margin: 0 auto;
        bottom: 20px;
        width: 100%;
        padding: 0 30px;
        transform-origin: bottom;
        transform: scaleY(0);
        transition: transform 0.2s ease-in-out;
    }

    &__item-group {
        list-style: none;
        border: 1px solid #f2f2f2;
        border-radius: 8px;
        overflow: hidden;
        width: 100%;
        padding: 0;
        margin: 0;

        & + .c-dropdown-nav__item-group {
            margin-top: 20px;
        }
    }

    &__item {
        padding: 20px;
        width: 100%;
        text-align: center;
        background-color: #fff;
        will-change: background-color;
        transition: all 0.1s ease-in-out;
        color: @mp-blue;
        cursor: pointer;
        user-select: none;
        font-size: 1.8rem;
        .wt-semi();

        &:hover {
            background-color: fadeout(mix(@mp-blue, #fff, 20%), 10%);
        }

        & + .c-dropdown-nav__item {
            border-top: 1px solid #dbdbdb;
        }

        &--disabled {
            cursor: not-allowed;
        }

        &--grey {
            color: @font-color-darker;
            .wt-regular();
        }
    }
}

.contentWrapper .c-dropdown-nav {
    .c-dropdown-nav__items {
        border-top: 0;

        &__item {
            background-color: fadeout(#fff, 10%);
        }
    }
}

// Desktop specific
.o-layout--desktop {
    .c-sub-header {
        position: relative;
        left: 0;
        top: 0;
        margin-bottom: 1.5rem;

        .c-dropdown-nav {
            position: absolute;
            left: 0;
            top: 0;
            width: auto;
            height: auto;
            top: 56px;
            background: transparent;

            &--right {
                left: auto;
                right: 0;
            }

            &__items {
                transform-origin: top;
                position: relative;
                padding: 0;
                max-width: 250px;
                margin: 0;
                bottom: auto;
            }

            &__item {
                background-color: fadeout(#fff, 10%);
                color: @font-color-darker;
                font-size: 1.5rem;
            }
        }
    }
}
