.o-stack {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    --space: 1rem;
    gap: var(--space, 1rem);

    * {
        margin: 0;
    }

    &--sm {
        > * + * {
            --space: 0.5rem;
        }
    }
    &--lg {
        > * + * {
            --space: 2rem;
        }
    }

    &--horizontal {
        flex-direction: row;
    }

    &--no-gutter {
        --space: 0rem;
    }
}

.o-panel .o-stack--splitted,
.o-panel.o-stack--splitted {
    > * + * {
        margin-inline: -10px;
        padding-inline: 10px;
        padding-top: var(--space, 1rem);
        border-top: 1px solid #f2f2f2;
    }
}
