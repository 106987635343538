.c-chat-message {
    position: relative;
    background: #fff;
    border-radius: 8px;
    padding: 15px 10px;
    margin-right: 50px;
    overflow: hidden;
    --body-color: @font-color-darker;
    --body-style: normal;

    &--is-own {
        margin-right: 0;
        margin-left: 50px;
        background: #dcf8c6;

        .c-chat-message__date {
            color: #4c8911;
        }
    }

    &--needs-translation {
        --body-color: @font-color;
        --body-style: italic;
    }

    & + .c-chat-message {
        margin-top: 5px;
    }

    &__author {
        display: flex;
        margin-bottom: 10px;
        position: relative;
        align-items: center;

        &-details {
            display: flex;
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: hidden;
        }

        &-avatar {
            margin: 0;
            margin-right: 10px;
            border-radius: 50%;
            overflow: hidden;
            width: 4rem;
            height: 4rem;
            flex-grow: 0;
            flex-shrink: 0;

            img {
                width: 100%;
            }
        }

        &-name {
            .wt-semi();
            display: flex;
            justify-content: space-between;
        }

        &-relation {
            .wt-regular();
            font-size: 1.1rem;
        }
    }

    &__details {
        color: @mp-blue;
        .wt-semi();
        font-size: 1.3rem;
        display: flex;
        margin-bottom: 0.5rem;
        align-items: center;
    }

    &__options {
        display: none;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition: background linear 0.2s;
        background: rgba(255, 255, 255, 0);
        z-index: 1;
        border-radius: 8px;

        &--is-visible {
            display: flex;
            pointer-events: all;
            background: rgba(255, 255, 255, 0.5);
        }
    }

    &__date {
    }

    &__status {
        font-size: 0;
        margin-left: 0.3rem;

        span {
            display: inline-block;
            width: 1rem;
            height: 1rem;

            svg {
                width: 100%;
                height: 100%;
            }

            & + span {
                margin-left: -0.2rem;
            }
        }

        path {
            /*fill: @font-color-light;*/
            fill: transparent;
        }

        &--sent {
            path {
                /*fill: @mp-blue;*/
                fill: @font-color-light;
            }
        }

        &--read {
            path {
                /*fill: #4c8911;*/
                fill: @mp-blue;
            }
        }
    }

    &--is-deleted {
        font-style: italic;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(255,255,255,0.4);
            pointer-events: none;
        }
    }

    &__body {
        font-size: 1.6rem;
        line-height: 25/16;
        white-space: pre-wrap;
        color: var(--body-color);
        font-style: var(--body-style);

        a {
            color: @mp-blue;
        }
    }

    &__media {
        display: block;
        overflow: hidden;
        padding-bottom: .5rem;
        a {
            flex-shrink: 1;
            display: inline-block;
        }
        img {
            max-width: 320px;
            display: block;
        }
    }
}
