// this is not finished yet
.c-input-group {
    display: block;
    position: relative;

    input:not([type=checkbox]):not([type=radio]), textarea {
        display: block;
        color: inherit;
        border: 0;
        outline: 0;
        padding: 10px 20px;
        border-radius: @border-radius;
        opacity: 1;
        width: 100%;
    }

    label {
        display: block;
    }

    &--is-invalid input, &--is-invalid textarea {
        box-shadow: 0 1px red;
    }

    &--is-valid input, &--is-valid textarea {
        box-shadow: 0 1px green;
    }

    &__password {
        input {
            padding-right: 40px;
        }

        &--show {
            position: absolute;
            font-size: 2.5rem;
            top: 50%;
            width: 40px;
            padding-right: 5px;
            right: 0;
            transform: translateY(-50%);
        }
    }
}

.c-form-checkbox {
    @size: 27px;
    @color-green: #5CB900;

    position: relative;
    display: block;
    display: flex;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    flex-grow: 0;
    align-items: center;
    cursor: pointer;
    user-select: none;

    input ~ label {
        margin-left: 10px;
        display: inline-block;
    }

    label::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: @size;
        height: @size;
        background: rgba(255, 255, 255, 1);
        border-radius: 50%;
        transition: background linear 0.1s;
        cursor: pointer;
        font-family: fontello;
        line-height: 27px;
        text-align: center;
        font-size: 1.7rem;
        font-weight: normal;
    }

    input {
        position: relative;
        display: inline-block;
        opacity: 0;
        cursor: pointer;
        width: @size;
        height: @size;
        margin: 0;
        padding: 0;
    }

    input:checked ~ label::before {
        background-color: @color-green;
        content: '\e80c';
    }
}





.c-form-search {
    flex-shrink: 0;
    background: #e6e6e6;
    border-radius: 6px;
    color: @font-color-dark;
    padding: 5px;
    display: flex;
    align-items: center;

    i {
        display: block;
        margin-right: 5px;
        cursor: pointer;
        font-size: 2rem;
    }

    input {
        margin: 0;
        background: transparent;
        border: 0;
        outline: 0;
        color: inherit;
        font-size: @size-medium;
        appearance: none;
        flex: 1;

        &::-webkit-search-cancel-button {
            appearance: none;
        }
    }

    i.fiep-icon-cancel-circled {
        font-size: 2rem;
        margin-right: 0;
    }
}


/* Check and radio-boxes - as we also want to use the same styling in ascx views */
div.zbr-checkbox, label.zbr-checkbox {
    @size: 27px;
    @color-green: #6ab814;

    box-sizing: content-box;
    position: relative;
    margin: 0;
    padding: 0;
    width: @size;
    height: @size;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 999px;
    flex-shrink: 0;
    flex-grow: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    span {
        margin-left: 30px;
        white-space: nowrap;
    }

    &.zbr-checkbox--disabled {
        &::before {
            background-color: rgb(232, 232, 232);
            /*          @c: luma(@color-green);
                                  background-color:rgb(@c, @c, @c);*/
        }
    }

    &::before {
        position: absolute;
        content: "";
        left: 3px;
        top: 3px;
        width: @size - 6;
        height: @size - 6;
        background: rgba(0, 0, 0, 0);
        border-radius: 999px;
        transition: background-color linear 0.1s;
    }

    &--small {
        width: 20px;
        height: 20px;

        &::before {
            width: 14px;
            height: 14px;
            background-size: 14px;
        }
    }

    &:focus:not(.zbr-checkbox--disabled):not([disabled]):not(.disabled)::before {
        background-color: saturate(@color-green, 50%);
    }

    &:active:not(.zbr-checkbox--disabled):not([disabled]):not(.disabled)::before {
        background-color: saturate(@color-green, 50%);

        &:hover::before, &:focus::before {
            background-color: #bbb;
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    &.disabled {
        cursor: default;
    }

    input:disabled {
        cursor: default;
    }

    &--checked,
    &--checked:hover:not(.zbr-checkbox--disabled):not([disabled]):not(.disabled) {
        &::before {
            background-color: @color-green;
            background-image: url("/publish/Assets/Images/check_square.png");
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    &.zbr-checkbox--disabled {
        cursor: default;
    }
}

div.zbr-radiobutton {
    --size: 26px;
    --inside-padding: 6px;

    &.zbr-radiobutton--small {
        --size: 16px;
        --inside-padding: 6px;
    }

    @color-green: #6ab814;
    position: relative;
    margin: 0;
    padding: 0;
    width: var(--size);
    height: var(--size);
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 50%;
    flex-shrink: 0;
    flex-grow: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &.disabled {
        cursor: default;
    }

    &::before {
        position: absolute;
        content: "";
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(var(--size) - var(--inside-padding));
        height: calc(var(--size) - var(--inside-padding));
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
        transition: background-color linear 0.1s;
    }

    &:focus:not(.zbr-checkbox--disabled):not([disabled]):not(.disabled)::before {
        background-color: saturate(@color-green, 50%);
    }

    &:active:not(.zbr-checkbox--disabled):not([disabled]):not(.disabled)::before {
        background-color: saturate(@color-green, 50%);

        &:hover::before,
        &:focus::before {
            background-color: #bbb;
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    input:disabled {
        cursor: default;
    }

    &--checked,
    &--checked:hover {
        &::before {
            background-color: @color-green;
        }
    }

    &.zbr-radiobutton--disabled {
        cursor: default;
    }
}
