.c-warning-box {
    --warning-box-background: @warningbox-backgroundcolor;
    background-color: var(--warning-box-background);
    margin: 8px 0;
    padding: 12px;
    max-height: 999px;
    transition: max-height 0.5s ease-in-out;
    position: relative;
    overflow: hidden;
    line-height: 2rem;
    display: flex;
    flex-shrink: 0;

    &--white {
        --warning-box-background: white;
    }

    &--grey {
        --warning-box-background: #F2F2F2;
    }

    &__title {
        margin: 0;
        font-size: 1.7rem;
        font-weight: 600;
        line-height: 1.5em;
        margin: 0;

        &:not(:empty) {
            margin-bottom: 8px;
        }
    }

    &__content {
        padding-top: .25em;
       padding-left:.5em; 
    }

    &__text {
        margin: 0;
        font-size: 1.5rem;
        line-height: 1.3em;

        p {
            margin-top: 0;
            margin-bottom: 0;

            & + p {
                margin-top: .5em;
            }
        }

        a, a:hover {
            color: @mp-blue;
        }
    }

    &__icon:not(:empty) {
        align-self: start; // align to the top for multiple lines
        &:not(.c-warning-box--with-title) {
            padding-top: 0.5em;
        }

        i.icon-attention {
            font-size: 2.5rem;
        }

        i.icon-info-circled {
            font-size: 2.5rem;
        }
    }

    .o-user-content--readmore {
        user-select: none;
        cursor: pointer;
        width: 100%;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        position: absolute;
        top: 82px;
        background-color: var(--warning-box-background);
        color: @mp-blue;
        padding: 8px 0;
        font-weight: 600;
        font-size: 1.3rem;
    }

    &.o-user-content--trimmable {
    }

    &.o-user-content--trimmed {
        transition: max-height 0.5s ease-in-out;
        max-height: 118px;

        .o-user-content--readmore {
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
        }

        &:after {
            background: rgba(255,255,255,0);
            /*margin-top:20px;*/
            /*background: linear-gradient(rgba(255,255,255,0), @warningbox-backgroundcolor);*/
        }
    }
}
