.u-mobile-only {
    .o-layout--desktop & {
        display: none;
    }
}

@media @is-mobile {
    .u-hide-on-mobile {
        display: none;
    }
}

@media print {
    .u-hide-on-print {
        display: none !important;
    }

    .back-button-wrapper {
        display: none;
    }
}
