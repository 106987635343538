.c-bottom-nav {
    --height: 64px;
    position: fixed;
    left: 0;
    bottom: 10px;
    width: 100%;
    display: flex;
    z-index: calc(var(--header-z-index) - 2);
    justify-content: center;

    &__wrapper {
        --width: 0px;
        height: calc(var(--height) + 20px); // 2 times the padding;
        margin: 0 auto;
        position: relative;
        display: flex;
        width: calc(var(--width) + 20px); // 2 times the padding
        will-change: width;
        overflow: hidden;
        transition: width 0.1s ease-in;
        padding: 10px; // to give room to the box shadow for the container
    }
    &__container {
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background-color: var(--color-white);
        border-radius: calc(var(--height) / 2);
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
        padding: 10px;
    }
}
