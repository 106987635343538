.c-reply-area {
    display: flex;
    border-radius: 0.8rem;
    overflow: hidden;
    padding: 1rem;
    background: #fff;
    align-items: center;
    position: relative;

    &__textarea {
        padding: 0;
        border: 0;
        outline: 0;
        margin: 0;
        font-size: 1.5rem;
        font-family: @font-family;
        color: @font-color-darker;
        flex-grow: 1;
        user-select: text;
        resize: vertical;
        outline: none;

        &::placeholder {
            font-family: @font-family;
            font-size: inherit;
            color: @font-color-lighter;
            border: 0;
            outline: 0;
        }

        &:focus {
            border: none;
        }

        &--error {
            color: fade(@red-accent, 50%);
        }
    }

    &__characters-left {
        color: @font-color-lighter;
        &--invalid {
            color: @red-accent;
        }
    }

    &__avatar {
    }

    &__attachment-button{
        cursor: pointer;
        font-size:0;
    }

    &__send-button {
        margin-left: 2rem;
        background: @green-accent;
        color: #fff;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        transition: background 0.3s linear;
        text-align: center;
        line-height: 3rem;
        cursor: pointer;

        &:hover,
        &:active {
            background-color: darken(@green-accent, 7%);
        }

        i::before {
            margin: 0;
            margin-left: -2px;
        }

        &--disabled {
            background: @background-list-grey;

            &:hover,
            &:active {
                background: @background-list-grey;
            }
        }
    }

    @supports (display: grid) {
        display: grid;
        grid-template-columns: auto 1fr auto auto auto;
        column-gap: 1rem;

        &--simple {
            grid-template-columns: auto 1fr auto;
        }

        .c-reply-area__send-button {
            margin-left: 0;
        }

        .c-reply-area__attachment-button svg {
            width: 30px;
            height: 30px
        }
    }

    &__wrapper {
        padding: 1rem;
        background-color: @background-list-grey;
        border-top: 1px solid #fff;

        .o-layout--mobile & {
            padding: 0;
            border-style: none;
            background-color: transparent;
        }
    }

    // modifiers
    &--no-padding {
        padding: 0;
    }
}
