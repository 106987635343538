:root {
    // heights
    --header-height: 65px;
    --header-z-index: 999;
    --header-text-color: #E1E9F2;
    // colors
    --color-white: theme('colors.white');
    --color-blue: theme('colors.blue.DEFAULT');
    --color-red: theme('colors.red');
    --color-gray: theme('colors.gray.DEFAULT');
    --color-gray-lighter: theme('colors.gray.lighter');
    --color-gray-light: theme('colors.gray.light');
    --color-gray-dark: theme('colors.gray.dark');
    --color-gray-darker: theme('colors.gray.darker');
    // fonts
    --font-family-default: 'Open Sans', sans-serif;
    --font-family-small: 'Source Sans Pro', sans-serif;
    --font-family-title: 'PT Sans', sans-serif;
    --vh: 100vh;
    --body-bottom-margin: 79px;
    --max-content-height: ~"calc(var(--vh) - var(--header-height) - var(--body-bottom-margin) - 80px)";
    --breadcrumb-height: 50px;
    --header-background-color: #007EBC;
    --border-color: #B7CEE5;
    --total-header-height: ~"calc(var(--header-height) + var(--breadcrumb-height))";
    --other-zone-color : theme('colors.orange');
}
.o-layout--desktop {
    --body-bottom-margin: 225px;
}
