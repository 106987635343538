.c-person-item {
    &--subitem {
        font-size: 1.3rem;
        flex-wrap: nowrap;
        display: flex;
        border: 0;


        &.wrappable {
            flex-wrap: wrap;
            gap: 10px;
        }

        &__avatar-container {
            display: flex;
            flex-grow: 1;
            min-height: 50px;
            align-items: center
        }

        &__details {
            display: flex;
            flex-direction: column;
        }

        &__avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: 10px;
        }

        &__name {
            .wt-semi();
            font-size: 1.5rem;
        }

        &__email {
            font-size: inherit;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &__actions {
            display: flex;
            justify-content: flex-end;
            flex-grow: 0;
            min-height: 50px;
            align-items: center;

            .c-btn {
                margin-left: 8px;
            }
        }
    }
}
