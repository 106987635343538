
#hamburger-menu {
    padding: 0;
    margin: 0;

    * {
        box-sizing: border-box;
    }

    li {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 50px;
        border-bottom: 1px solid #7d7d7d;
        cursor: pointer;
        padding-left: 20px;

        &:first-child {
            height: 75px;

            div {
                line-height: 75px;

                span {
                    &.name {
                        line-height: 45px;
                        font-weight: 600;
                        padding-top: 5px;
                    }

                    &.email {
                        line-height: 30px;
                        font-size: 1.2rem;
                        margin-left: 51px;
                        margin-top: -18px;
                    }
                }
            }
        }

        white-space: nowrap;

        span, a {
            line-height: 50px;
            display: block;
        }
    }

    .menu-disclaimer-link a {
        display: inline;
    }

    .badge-active {
        position: relative;

        a:after {
            content: '';
            position: absolute;
            right: 12px;
            width: 0.5em;
            height: 0.5em;
            border-radius: 50%;
            background: red;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%)
        }
    }

    .menu-zopim-chat {
        display: flex;
        align-items: center;

        .chat {
            flex-grow: 1;
        }

        > .new-message-icon {
            @newMessageDimension: 14px;
            background: #6AB814;
            width: @newMessageDimension;
            height: @newMessageDimension;
            border-radius: 50%;
            font-size: @newMessageDimension;
            padding: 5px;
            color: #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            display: none;
        }

        &.disabled {
            color: #555;
        }

        &.new-messages {
            > .new-message-icon {
                display: flex;
            }
        }
    }
}