.c-tag-editor {
    .tagify {
        --tag-bg: #c890a7;
        --tag-text-color: #fff;
        --tag-pad: 10px;
        --tag-inset-shadow-size: 23px;
        --tag-remove-btn-color: #fff;
        --tags-border-color: transparent;
        --tags-hover-border-color: transparent;
        display: flex;
        margin-left: -5px;
        margin-right: -5px;
        gap:8px;
    }

    .tagify--loading {
        pointer-events: none;
    }
}

:root{
    --tagify-dd-item-pad: 10px;
    --tagify-dd-color-primary: #c890a7;
}

.tagify__dropdown {
    font-size: 13px;
}