.c-chat-header {
    display: flex;
    font-size: 1.3rem;
    color: #222;
    align-items: center;
    .wt-semi();

    &__avatar-group {
        .c-chat-header__avatar {
            float: left;
        }

        margin-right: 10px;
    }

    &__name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .wt-semi();
        overflow: hidden;
        justify-content: center;
    }

    &__relation {
        .wt-regular();
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1.1rem;
    }

    &__avatar {
        margin-right: 12px;
    }
}
