.c-validation-summary{
    display: block;
    padding: 10px;
    border-radius: @border-radius-small;

    ul,li {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &--errors {
        background-color: @red-accent;
        color: #fff;
    }
}