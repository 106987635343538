.o-layout--mobile {
    --total-header-height: var(--header-height);

    .submit-wrapper {
        .c-btn--submit {
            width: 100%;
            border-radius: 44px;
        }
    }
    // we have navigation-header, if we are alowed a back button
    &.navigation-header {
        .submit-wrapper {
            .c-btn--submit {
                border-radius: @border-radius;
            }
        }

        .back-button-wrapper {
            .c-btn--back, .c-btn--secondary {
                color: var(--header-text-color);
                border-radius: 0;


                &:hover, &:active, &:focus {
                    background-color: color-mix(in srgb, var(--header-background-color) 25% black) !important;
                }

                &:active {
                    background-color: color-mix(in srgb, var(--header-background-color) 25% black) !important;

                    span.icon-back {
                        color: color-mix(in srgb, var(--header-background-color) 40%, var(--header-text-color));
                    }
                }

                span.icon-back {
                    color: var(--header-text-color);
                    transition: color 0.1s;
                }
            }

            a.c-btn--back {
                color: var(--header-text-color) !important;
            }
        }

        &--alternative {
            // hide the buttons container when we have the alternative mounted
            // because the buttons are really inside this alternative header
            .buttonsContainer, .c-buttons-container {
                display: none !important;
            }

            .navigation-header-wrapper {
                display: none !important;
            }
        }

        #navigation-header .navigation-header-wrapper,
        #navigation-header .vue-portal-target {
            background: var(--header-background-color);
            color: var(--header-text-color);
        }

        .header-title,
        .c-chat-header {
            color: var(--header-text-color) !important;
        }

        #navigation-header {
            height: var(--header-height);

            .c-sub-header {
                height: 100%;
            }
        }
    }

    .blockOverlay {
        position: fixed !important;
    }
}

.o-layout--desktop {
    .back-button-wrapper {
        a svg,
        a span {
            display: none;
        }
    }

    .u-mobile-only {
        display: none;
    }

    .back-button-wrapper ~ .submit-wrapper {
        margin-left: 10px;
    }

    .buttonsContainer .submit-wrapper {
        margin-left: 0;
    }
}

.o-layout--new {
    #content {
        position: relative;
        margin-top: 0;
        top: 115px;
    }
}
