
#header {
    *, & {
        box-sizing: border-box;
    }
    transition: height 0.2s ease-in;

    .logoff {
        float: right;
        height: var(--header-height);
        font-size: 1.5em;
        line-height: var(--header-height);
        color: #E1E9F2;
        margin-right: 10px;
        display:flex;
        align-items:center;
    }

    .organization-logo {
        left: 15px;
    }

    &.school .activeperiod {
        height: var(--header-height);
    }

    .avatar {
        right: 15px;
        position: absolute;
        width: 40px;
        height: 40px;
        top:  calc((var(--header-height) - 40px) / 2);

        .logo-rounded {
            border: 1px solid #f2f2f2;
            -o-object-fit: cover;
            object-fit: cover;
            transform: translateY(-50%);
            top: 50%;
            position: absolute;
            box-sizing: border-box;
        }

        &-badge {
            box-sizing: border-box;
            --badge-size: 14px;
            display: block;
            position: absolute;
            right: -2px;
            top: -2px;
            border: 1px solid #fff;
            width: var(--badge-size);
            height: var(--badge-size);
            background: red;
            border-radius: 50%;
            opacity: 0;
            -webkit-transition: opacity 0.1s ease-in;
            transition: opacity 0.1s ease-in;

            &--is-active {
                opacity: 1;
            }
        }
    }

    .organization-logo {
        height: var(--header-height);
        width: 40px;
        display: inline-block;
        position: absolute;

        img:not(.flag) {
            transform: translateY(-50%);
            top: 50%;
            border-radius: 999px;
            width: 40px;
            height: 40px;
            position: absolute;
        }

        img.flag {
            position: absolute;
            width: 16px;
            height: 16px;
            border-radius: 999px;
            overflow: hidden;
            object-fit: cover;
            filter: grayscale(30%);
            padding: 1px;
            background: white;
            left: 26px;
            top: 42px;
        }
    }

    .hamburger {
        cursor: pointer;
        float: right;
        text-align: center;
        clear: right;
        width: 54px;
        height: 60px;

        svg {
            margin-top: 22px;
        }
    }
}