@white: #ebf4f9;

.c-btn {
    user-select: none;
    white-space: nowrap;
    cursor: pointer;
    background-image: none;
    display: inline-block;
    width: auto;
    min-width: auto;
    min-height: auto;
    width: max-content;
    color: inherit;
    text-align: center;
    margin: 0;
    border-width: 1px;
    border-style: solid;
    align-self: center;
    .wt-semi();
    .ft-open-sans();
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    .button-variant(inherit, #fff, #dbdbdb);
    .button-size(0.5em, 1em, 1.4rem);

    &[disabled],
    &.disabled {
        cursor: default;
        pointer-events: none;
    }

    &[type="submit"] {
        height: auto;
        line-height: normal;
        // assigned this is a --primary variant
        font-size: 1.4rem;
    }

    height: auto;

    &--outline {
        .button-variant( @font-color-darker, @background-list-grey, @border-color-light );
    }

    &--primary {
        .button-variant(#fff, @button-green, @button-green);
    }

    &--primary-alternative {
        .button-variant(@mp-blue, #f7f7f7, #f7f7f7);
    }

    &--secondary {
        .button-variant(@grey-accent, #e7e7e7, #e7e7e7);

        label {
            margin: 0;
        }
    }

    &--delete-squared {
        .button-variant(#fff, @button-red-color, @button-red-color);
        .large-squared();
    }

    &--delete {
        .button-variant(#fff, @button-red-color, @button-red-color);
    }

    &--dark {
        .button-variant(#FFF, #444, #444);
    }

    &--transparent {
        .button-variant(#fff, transparent, #fff);
    }

    &--lg {
        .button-size(1rem, 1.5rem, 1.5rem);
    }

    &--sm {
        .button-size(0.5rem, 1rem, 1.3rem, normal, 4px);
    }

    &--square {
        border-radius: 0;
    }

    .large-squared() {
        .button-size(1rem, 1.5rem, 1.5rem);
        border-radius: 4px;
    }
    // composed buttons
    &--submit {
        .button-variant(#fff, @button-green, @button-green);
        .large-squared();
    }

    &--grey {
        .button-variant(#888, #e7e7e7, #f1f1f1);
    }

    &--blue {
        .button-variant(#fff, @mp-blue, @mp-blue);
    }

    &--back {
        .button-variant(@grey-accent, #e7e7e7, #e7e7e7);

        label {
            margin: 0;
            cursor: pointer;
        }

        span.icon-back {
            color: @mp-blue;
            font-size: 1.7rem;
        }
    }

    &--back-alternative {
        .button-variant(#f7f7f7, @mp-blue, #f7f7f7);
        /*.large-squared();*/

        label {
            margin: 0;
            cursor: pointer;
        }

        span.icon-back {
            color: @mp-blue;
            font-size: 1.7rem;
        }
    }

    &--back-white {
        .button-variant(#222, #fff, #fff);

        label {
            margin: 0;
            cursor: pointer;
        }

        span.icon-back {
            color: @mp-blue;
            font-size: 1.7rem;
        }
    }

    @media @is-mobile {
        &--mobile-full-width,
        &.mobile-full-width {
            width: 100%;
            display: block;
            float: none !important;
        }
    }

    &--status-new {
        // can create new
        .button-variant(@button-font-color, @button-green, @button-green);
    }

    &--status-busy {
        // busy
        .button-variant( @button-grey-font-color, @button-grey-color, @button-grey-color );
    }

    &--status-scheduled {
        // scheduled
        .button-variant( @button-font-color, @button-red-color, @button-red-color );
    }

    &--status-check {
        // show overview
        .button-variant( @button-font-color, @button-blue-color, @button-blue-color );
    }

    &--icon {
        .button-variant(inherit, @button-grey-color, @button-grey-color);
        padding: 0.5em;

        i {
            font-size: inherit;
        }
    }

    &--svg-icon {
        height: 1.5em; // to make it the same height as text
        box-sizing: content-box; // exclided padding
        display: flex; // make the svg centered
        align-items: center;
    }
}

.c-toggle-button {
    position: relative;
    box-sizing: border-box;
    display: inline-block;

    * {
        box-sizing: border-box;
    }

    &--item {
        width: 40px;
        background: #2e394d;
        height: 24px;
        display: inline-block;
        border-radius: 50px;
        position: relative;
        transition: all 0.3s ease;
        transform-origin: 20% center;
        background: #cecece;
        border: 1px solid #cecece;
        cursor: pointer;
        margin: 0;

        &:before {
            position: absolute;
            content: "";
            display: block;
            transition: all 0.2s ease;
            top: 0px;
            left: 1px;
            border-radius: 9999px;
            transition: 0.3s ease;
            border: none;
            width: 22px;
            height: 22px;
            background: #fff;
        }
    }

    &.disabled {
        label.c-toggle-button--item {
            background: #e9e9e9;
            border-color: #e9e9e9;
            cursor: not-allowed;
        }
    }

    &.selected {
        label.c-toggle-button--item {
            background: #5cba00;
            border-color: #5cba00;

            &:before {
                left: 16px;
            }
        }

        &.disabled {
            label.c-toggle-button--item {
                background: #bee399;
                border-color: #bee399;
            }
        }
    }
}

.c-toggle-button1 {
    width: auto;
    width: max-content;
    display: flex;
    justify-content: flex-start;
    padding: 3px;
    border-radius: @border-radius;
    margin-bottom: 30px;
    background: #0071a9;
    .wt-semi();

    &__item {
        background: transparent;
        color: @white;
        padding: 5px 20px;
        .ft-open-sans();
        font-size: 1.5rem;

        &--active {
            border-radius: @border-radius;
            background: #f7f7f7;
            color: @mp-blue;
            .wt-bold();
        }
    }
}

.c-buttons-container {
    display: grid;
    grid-template-areas: "back delete submit";
    grid-template-columns: 1fr auto auto;
    align-content: center;
    margin-top: 30px;
    gap: 10px;

    &.right {
        text-align: right;
        justify-content: flex-end;
    }

    &.center {
        text-align: center;
        justify-content: center;
    }

    .o-layout--desktop & {
        .c-btn {
            padding: 0.7em 2em;
        }
    }

    .c-btn--back,
    .back-button-wrapper {
        grid-area: back;
    }
    .c-btn--delete {
        grid-area: delete;
        width: auto;
    }
    .c-btn--primary,
    .submit-wrapper {
        grid-area: submit;
    }

    .o-layout--mobile & {
        grid-template-areas: "delete";
        grid-template-columns: 1fr;
        align-items: stretch;

        padding-inline: 15px;
    }
}
