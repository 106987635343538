/* needed for bundle (basestyles.css) & classic parts */
@import '../../../Assets/Css/StyleGuides/Jheronimus/jheronimus-variables';
/* */


.c-breadcrumb-nav {
    color: var(--header-text-color);
    position: fixed !important;
    z-index: ~"calc(var(--header-z-index) - 2)";
    top: var(--header-height);
    height: var(--breadcrumb-height);
    left: 0;
    right: 0;
    margin: 0;
    padding: 10px 15px;
    background-color: darken(@mpHeaderBackground, 8%);
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;


    &, * {
        box-sizing: border-box
    }

    .help {
        float: right;
    }

    .help img {
        display: block;
        margin: 0;
        margin-top: 2.5px;

        &:hover {
            background-color: #6B8AA7;
        }
    }

    > div {
        height: 100%;
        line-height: calc(var(--breadcrumb-height) - 20px);
        color: #fff;
        font-size: 13px;
        display: flex;


        .crumb {
            display: block;
            float: left;
            vertical-align: middle;
            height: 100%;
            position: relative;
            color: rgba(255,255,255, .7);
            transition: color 0.1s linear;

            > span {
                font-size: inherit;
            }

            + .crumb {
                margin-left: 2em;

                &:before {
                    content: '/';
                    position: absolute;
                    display: block;
                    top: 0;
                    left: -15px;
                    height: 20px;
                    width: 11px;
                    font-size: 15px;
                    color: rgba(255,255,255, .5);
                }
            }

            &.last {
                color: rgba(255,255,255,1);
            }
        }
    }

    .help-and-menu {
        /*  float: right;
        vertical-align: middle;*/
        display: flex;
        align-content: center;
        /*align-content:center;*/

        a.breadcrumb-menu, a.breadcrumb-help {
            vertical-align: middle;
            cursor: pointer;
            margin-left: 20px;
            svg {
                height: 24px;

                path {
                    fill: var(--header-text-color);
                }
            }
        }

        .company {
            margin-left: 0;
            padding-left: 0;
        }

        .company a > .logo, .company > .logo {
            vertical-align: baseline;
            margin-left: 4px;
            margin-top: 4px;
            margin-right: 8px;
            display: inline-block;
            width: 23px;
            height: 23px;
            border: 1px solid var(--border-color);
            border-radius: 50%;
        }

        .company a > span + span, .company > span + span {
            vertical-align: top;
        }
    }
}
