.c-translate-attribution {
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    height: 40px;
    font-family: @small-font-family;
    font-weight: 600;

    &__logo {
        margin-left: 10px;
        font-size: 11px;
        color: @font-color-darker;

        img.logo-ms {
            max-width: 60px;
            vertical-align: middle;
            width: unset;
            height: unset;
            object-fit: unset;
        }

        img.logo-google {
            max-width: 120px;
            vertical-align: middle;
            width: unset;
            height: unset;
            object-fit: unset;
        }


        &-google img {
            vertical-align: middle;
            max-width: 120px;
        }

        &-msft img {
            vertical-align: middle;
            max-width: 60px;
        }
    }

    &__button {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #eaeaea;
        padding: 5px;
        color: #606060;
        font-size: 14px;
    }

    .translate-icon {
        border-radius: 0;
        width: 28px;
        height: unset;
        margin-right: 10px;
    }
    // .translate-to, .translate-item {
    //     align-items: center;
    //     display: flex;
    //     flex-direction: row;
    //     cursor: pointer;
    //     // font-family: @subHeadingFontFamily;
    //     font-size: 1.4rem;
    //     font-weight: 600;
    //     img.translate-icon {
    //         width: 27px;
    //         height: 23px;
    //         margin-right: 10px;
    //     }
    // }

    a {
        // color: @textGreyLight;
    }
}
