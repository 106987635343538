@import "~alertifyjs/build/css/alertify.css";
 
// theme logic here!
.alertify {
    font-size: 15px;
    box-sizing: border-box;

    &-notifier {
        color: #fff;
        font-size: 15px;
        user-select: none;
        box-sizing: border-box;

        * {
            box-sizing: border-box;
        }
        .ajs-message.ajs-error,
        .ajs-message.ajs-error {
            opacity: 0;
            // we need an important here, as we set the default transition for alertify to off
            // which will cause an other !important setting in it's vendor css
            transition: opacity .2s ease-in-out !important;
        }
        .ajs-message.ajs-error {
            background: fade(#ff0000, 85%);
        }

        .ajs-message.ajs-success {
            background-color: @button-green;
        }

        &.ajs-top {
            top: 65px;
            top: var(--header-height);

            .ajs-message.ajs-success.ajs-visible,
            .ajs-message.ajs-error.ajs-visible {
                margin-top: 0;
                width: 100vw;
                opacity: 1;
            }
        }
    }

    .ajs-modal,
    .ajs-dialog {
        padding: 0;
        border-radius: @border-radius;
        overflow: hidden;
    }

    // try to center it vertically
    .ajs-dialog {
        margin-top: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .ajs-header {
        padding: 20px 20px 0;
        margin: 0;
        font-size: 23px;
    }

    .ajs-body {
        .ajs-content {
            padding: 10px 20px;

            p {
                margin: 0;
                margin-bottom: 1em;
            }
        }
    }

    .ajs-footer {
        padding: 10px 20px;
        margin: 0;

        .ajs-buttons,
        .ajs-buttons.ajs-primary {
            display: flex;
            flex-direction: row-reverse;
            align-items: stretch;

            .ajs-button {
                .button-variant(inherit, #fff, #dbdbdb);
                margin: 0;
                padding: 10px 15px;
                min-width: 100px;
                border: none;
                cursor: pointer;
                outline: none;

                & + .ajs-button {
                    margin-right: 5px; // right because of row-reverse!
                }

                &.ajs-ok {
                    .button-variant(#fff, @button-green, @button-green);
                    .wt-semi();
                }

                &.ajs-cancel {
                    .button-variant(#888, #f1f1f1, #f0f0f0);
                }
                &.ajs-delete {
                    .button-variant(#fff, @button-red-color, @button-red-color);
                    .wt-semi();
                }
            }
        }
    }

    .ajs-commands {
        display: none;
    }

    .ajs-input {
        border-style: solid;
        border-width: 1px;
        border-color: #e0e0e0;
        margin: 0;
        padding: 10px;
        color: #3488d2;
        width: 100%;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
    }

    .ajs-dimmer {
        background-color: fadeout(#222, 20%);
        opacity: 1;
    }

    .ajs-dimmer,
    .ajs-modal {
        transition-duration: .3s;
    }
}
