body {
    --form-border-color: @background-grey-lighter;
    --accent-color: @blue-accent;
    --form-background-color: #fff;
    --color-black: #222;
    --form-color: var(--accent-color);
}
.form-input,
.form-textarea,
.form-select,
.form-multiselect {
    display: block;
    padding: 18px;
    width: 100%;
    font-size: 1.5rem;
    margin-top: 0.25rem;
    color: var(--form-color);
    border-width: 1px;
    border-style: solid;
    border-color: var(--form-border-color);
    background-color: var(--form-background-color);

    &:focus {
        --form-border-color: var(--accent-color);
        border-color: var(--form-border-color);
    }
}

.form-checkbox,
.form-radio {
    width: 1.4rem;
    height: 1.4rem;
    color: var(--accent-color);

    &:focus {
        --tw-ring-color: var(--accent-color);
    }
}

.form-input,
.form-textarea {
    &::placeholder {
        color: var(--color-black);
    }
}