@font-face {
  font-family: 'fiep';
  src: url('../font/fiep.eot?57347821');
  src: url('../font/fiep.eot?57347821#iefix') format('embedded-opentype'),
       url('../font/fiep.woff2?57347821') format('woff2'),
       url('../font/fiep.woff?57347821') format('woff'),
       url('../font/fiep.ttf?57347821') format('truetype'),
       url('../font/fiep.svg?57347821#fiep') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fiep';
    src: url('../font/fiep.svg?57347821#fiep') format('svg');
  }
}
*/
[class^="fiep-icon-"]:before, [class*=" fiep-icon-"]:before {
  font-family: "fiep";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.fiep-icon-search:before { content: '\e800'; } /* '' */
.fiep-icon-cancel-circled:before { content: '\e801'; } /* '' */
.fiep-icon-illness-off-icon-1:before { content: '\e809'; } /* '' */
.fiep-icon-dot-3:before { content: '\e81d'; } /* '' */
.fiep-icon-overslept-on-icon:before { content: '\e821'; } /* '' */
.fiep-icon-illness-on-icon:before { content: '\e824'; } /* '' */
.fiep-icon-coronacomplaints-on-icon:before { content: '\e825'; } /* '' */
.fiep-icon-coronaquarantine-on-icon:before { content: '\e826'; } /* '' */
.fiep-icon-coronatest-on-icon:before { content: '\e827'; } /* '' */
.fiep-icon-ind-off-icon:before { content: '\e82d'; } /* '' */
.fiep-icon-ind-on-icon:before { content: '\e82e'; } /* '' */
.fiep-icon-legal-on-icon:before { content: '\e82f'; } /* '' */
.fiep-icon-legal-off-icon:before { content: '\e830'; } /* '' */
.fiep-icon-wedding-on-icon:before { content: '\e900'; } /* '' */
.fiep-icon-wedding-off-icon:before { content: '\e901'; } /* '' */
.fiep-icon-specialist-on-icon:before { content: '\e902'; } /* '' */
.fiep-icon-specialist-off-icon:before { content: '\e903'; } /* '' */
.fiep-icon-dentist-on-icon:before { content: '\e904'; } /* '' */
.fiep-icon-dentist-off-icon:before { content: '\e905'; } /* '' */
.fiep-icon-gp-on-icon:before { content: '\e906'; } /* '' */
.fiep-icon-gp-off-icon:before { content: '\e907'; } /* '' */
.fiep-icon-illness-on-icon-1:before { content: '\e908'; } /* '' */
.fiep-icon-remark-icon:before { content: '\e90e'; } /* '' */
.fiep-icon-funeral-on-icon:before { content: '\e910'; } /* '' */
.fiep-icon-funeral-off-icon:before { content: '\e911'; } /* '' */
.fiep-icon-religious-on-icon:before { content: '\e912'; } /* '' */
.fiep-icon-religious-off-icon:before { content: '\e913'; } /* '' */
.fiep-icon-right:before { content: '\e950'; } /* '' */
.fiep-icon-pencil:before { content: '\e951'; } /* '' */
.fiep-icon-right-open-big:before { content: '\e952'; } /* '' */
.fiep-icon-heart-empty:before { content: '\e953'; } /* '' */
.fiep-icon-heart:before { content: '\e954'; } /* '' */
.fiep-icon-forward:before { content: '\e955'; } /* '' */
.fiep-icon-doc-text:before { content: '\f0f6'; } /* '' */
.fiep-icon-file-doc-inv:before { content: '\f15b'; } /* '' */
.fiep-icon-file-pdf:before { content: '\f1c1'; } /* '' */
.fiep-icon-file-word:before { content: '\f1c2'; } /* '' */
.fiep-icon-file-excel:before { content: '\f1c3'; } /* '' */
.fiep-icon-file-powerpoint:before { content: '\f1c4'; } /* '' */
.fiep-icon-file-image:before { content: '\f1c5'; } /* '' */
.fiep-icon-file-archive:before { content: '\f1c6'; } /* '' */
.fiep-icon-file-audio:before { content: '\f1c7'; } /* '' */
.fiep-icon-file-video:before { content: '\f1c8'; } /* '' */
.fiep-icon-file-code:before { content: '\f1c9'; } /* '' */
