.c-unstyled-list {
    padding: 0;
    margin: 0;
    list-style: none;
}
.c-unstyled-list-item {
    padding: 0;
    margin: 0;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    min-height: 5.5rem;

    &--no-padding {
        padding-left: 0;
        padding-right: 0;
    }

    &--clickable {
        transition: 0.1s background-color linear;
        &:active {
            background-color: fade(@mp-blue, 5%);
        }
    }
}

.c-topic-list-item {
    flex-wrap: nowrap;
    cursor: pointer;
    min-height: 7.5rem;
    padding-right: 20px;

    &__avatar-group {
        margin: 0;
        margin-right: 15px;
        flex-grow: 0;
        flex-shrink: 0;
        padding: 0;
        width: 6.5rem;
        min-width: 6.5rem;
        height: 6.5rem;
        contain: strict;
    }

    // &__avatar {
    //     border-radius: 50%;
    //     flex-grow: 0;
    //     flex-shrink: 0;
    //     margin: 0;
    //     overflow: hidden;
    //     padding: 0;
    //     width: 6.5rem;
    //     height: 6.5rem;
    //     margin-right: 10px;
    //     contain: strict;
    //     >* {
    //         width: 100%;
    //         height: 100%;
    //     }
    // }

    &__details {
        display: flex;
        overflow: hidden;
        flex-direction: column;
        flex-grow: 1;
        font-size: 1.3rem;
    }

    &__title-and-time {
        .wt-semi();
        color: @mp-blue;
        font-size: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        flex: 1;
    }

    &__status {
        font-size: 0;
        margin-left: 0.3rem;

        span {
            display: inline-block;
            width: 1rem;
            height: 1rem;

            svg {
                width: 100%;
                height: 100%;
            }

            & + span {
                margin-left: -0.2rem;
            }
        }

        path {
            fill: @font-color-light;
        }

        &--sent {
            path {
                fill: @font-color-light;
            }
        }

        &--read {
            path {
                fill: @mp-blue;
            }
        }
    }

    &__time {
        color: @font-color-light;
        font-size: 1.3rem;
        .wt-regular();
    }

    &__receiver {
        font-size: 1.1rem;
        margin-bottom: 0.3em;
        max-height: 3em;
        overflow: hidden;

        &-name {
            .wt-semi();
        }
        &-relation {
            color: @font-color-light;
        }
    }

    &__message {
        white-space: nowrap;
        overflow: hidden;
        min-height: 0;
        text-overflow: ellipsis;
        .ft-source-sans();
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @supports (display: grid) {
            display: grid;
            grid-template-columns: auto auto 1fr auto;

            &-tags {
                grid-column: 1;
            }
            &-author {
                grid-column: 2;
            }

            &-body {
                grid-column: 3;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.2;
            }
            &-indicator {
                grid-column: 4;
            }
        }

        &-author {
            .wt-semi();

            .icon-topic {
                color: @button-green;
                margin-right: 0px;
            }

            &--me {
                .icon-topic {
                    color: #999;
                }
            }
        }

        &-body {
            font-size: 1.5rem;
            flex-grow: 1;
        }

        &-indicator {
            display: block;
            flex-shrink: 0;
            flex-grow: 0;
            background-color: @button-green;
            border-radius: 50%;
            width: 1em;
            height: 1em;
        }
    }

    &__tags {
        display: flex;
        margin-right: 10px;
    }

    &__auto-archive {
        @bg: #fff;
        position: absolute;
        right: 10px;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background: linear-gradient(to right, fade(@bg, 50%), fade(@bg, 95%));
        [class*="icon"] {
            color: #ffa000;
            font-size: 2rem;
        }

        & > div {
            padding: 0 2rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            background-color: @bg;
        }

        p {
            font-size: 1.3rem;
            margin: 0;
            margin-top: 0.25rem;
            font-family: @font-pt-sans;
        }
    }
}
