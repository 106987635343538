.o-menu-block-target {
    // z-index: calc(var(--header-z-index) - 1) !important;
    // position: relative;

    &.is-blocked {
        pointer-events: none;
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
}
