/*
    JHERONIMUS StyleGuide - 2016.1
    Variables
*/
/*
    #### COLORS ####
*/
@mpHeaderBackground: #007ebc;

@button-green: #6FB625;
@button-green-color: #6FB625;
@button-blue-color: #3488D2;
@button-grey-color: #f1f1f1;
@button-red-color: #FD3D30;
@button-font-color: #fff;
@button-grey-font-color: #888;
@button-darkgrey-color:#d3d3d3;
@button-yellow-color: #FFA500;

@font-color: #666;
@font-color-light: #999;
@font-color-lighter: #bbb;
@font-color-dark: #5e5e5e;
@font-color-darker: #222;
@font-color-title: #3488D2;

@border-color: #E0E0E0;
@border-color-light: #DBDBDB ;
@background-grey: #eaeaea;
@background-grey-lighter: #f1f1f1;
@background-progress-bar: #E1E1E1;

@background-list-grey: #f2f2f2;
@background-list-grey-light: #f7f7f7;
@background-list-grey-lighter: #f9f9f9;

@red-accent: #c60000;
@blue-accent: #3488D2;
@green-accent: #6FB625;
@orange-accent: #F09100;
@fiep-pink: #e3017a;
@grey-accent: #606060;
@purple-accent: #B6778A;
@icon-color:transparent;
@icon-border-color:@border-color;

@active-color: #007ebc;
@hover-grey:#f7f7f7;

@mp-blue: @active-color;
@fiep-magenta: #E4017A;
@outgoing-orange: @orange-accent;
@warningbox-backgroundcolor: #FFFED5;

/*
    MEDIA QUERIES
*/
@is-mobile: ~ "only screen and (max-width: 760px)";
@is-mobile-landscape: ~ "only screen and (max-width: 760px) and (orientation landscape)";
@is-desktop: ~ "only screen and (min-width: 760px)";
@is-minimal-mobile: ~ "only screen and (max-width: 640px)";
@is-minimal-desktop: ~ "only screen and (min-width:640px)";
/*
    ### FONT-SIZE ####
*/
@size-H1: 2.4rem;
@size-H2: 2.0rem;
@size-H3: 1.5rem;
@size-extra-extra-extra-large: 3.5rem;
@size-extra-extra-large: 2.2rem;
@size-extra-large: 1.8rem;
@size-larger: 1.5rem;
@size-large: 1.4rem;
@size-medium: 1.3rem;
@size-small: 1.2rem;
@size-extra-small: 1.1rem;

@font-family: 'Open Sans', sans-serif;
@small-font-family: 'Source Sans Pro', sans-serif;
@title-font-family: 'PT Sans', sans-serif;
@alternate-title-font-family: 'Poppins', sans-serif;

@font-source-sans: @small-font-family;
@font-open-sans: @font-family;
@font-pt-sans: @title-font-family;

@border-radius: 8px;
@border-radius-small: 4px;
// #########
/*
    Images
*/
@icons-sprite: url('../../../Images/StyleGuides/Jheronimus/icons.png');


.overFlowEllipsis() {
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.absoluteCenterY() {
    position: absolute;
    top: 50%;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* FUNCTIONS */
.generate-overlaid-avatars(@maxNumber, @index: 1) when(@index =< @maxNumber) {
	@nth: @index+1;

	.avatar:nth-child(@{nth}) {
		transform: translateX(@index*-33%);
	}

	.generate-overlaid-avatars(@maxNumber, @index+1);
}

/* STYLED LISTS */
@list-item-background-color: @background-grey-lighter;
@list-item-border-color: @border-color;
@list-item-inactive-background-color: @background-list-grey-lighter;
@list-item-inactive-text-color: #588FD3;
@list-gutter: 8px;


.wt-semi() {
    font-weight: 600;
}

.wt-bold(){
    font-weight: 700;
}

.wt-regular() {
    font-weight: 400;
}

.ft-source-sans(){
    font-family: @font-source-sans;
}
.ft-pt-sans() {
    font-family: @font-pt-sans;
}
.ft-open-sans(){
    font-family: @font-open-sans;
}
.ft-poppins() {
    font-family: @alternate-title-font-family;
}

.truncating-text() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ft-title() {
    .ft-poppins();
    font-weight: 600;
    font-size: 2.3rem;
}
@import '../../Assets/Css/StyleGuides/Jheronimus/jheronimus-variables';

@import './settings/_variables';

@import 'tailwindcss/base';
@import "../../Assets/Glyphs/css/fontello.css";
@import "../../Assets/Glyphs/css/fiep.css";

.ft-icon {
    background: none;
}

@import "./tools/_mixins";

@import './elements/_icons';
@import './elements/_nav';
@import './elements/_header';
@import './elements/_body';

@import './objects/_title';
@import './objects/_panel';
@import './objects/_forms';
@import './objects/_menu-block-target';
@import './objects/_stack';
@import './objects/_grid';
@import './objects/_v-select-overrides';

@import 'tailwindcss/components';

@import "./components/_placeholders";
@import "./components/_form";
@import "./components/_buttons";
@import "./components/_tabs";
@import "./components/_inputs";
@import "./components/_list-item";
@import "./components/_person-list-item";
@import "./components/_active-period-indicator";
@import "./components/_chat-header";
@import "./components/_settings-menu";
@import "./components/_sub-header";
@import "./components/_hamburger-menu";
@import "./components/_avatar-group";
@import "./components/_tags";
@import './components/_messages';
@import './components/_reply-area';
@import './components/_chat-message';
@import "./components/_person-item";
@import "./components/_validation";
@import "./components/_warning-box";
@import "./components/_translate";
@import "./components/_bottom-nav";

@import "./components/_breadcrumb";
@import './components/_sitemenu';
@import './components/_app-header';
@import './components/_company-selector-flyout';
@import './components/_tag-editor';

@import 'tailwindcss/utilities';
@import "./utilities/_mobile_diff";
@import "./utilities/_alertify";
@import './utilities/_text-overflow';
@import './utilities/_utilities';
@import './utilities/_overrides';
/*
    CSS variables
*/
:root {
    --breadcrumb-height: 50px;
    --header-text-color: #E1E9F2;
    --header-height: 65px;
    --header-background-color: #007EBC;
    --header-text-color: #fff;
    --border-color: #B7CEE5;
    --total-header-height: ~"calc(var(--header-height) + var(--breadcrumb-height))";
}
/*

*/
.pswp {
    button {
        min-width: auto;
    }
}

.v-expand {
    &-enter-active,
    &-leave-active {
        transition: max-height 0.2s ease-in-out;
    }

    &-enter,
    &-leave-to {
        max-height: 0 !important;
    }
}

.v-fade {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.2s ease-in-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0 !important;
    }
}

.v-list {
    &-enter-active,
    &-leave-active {
        transition: all 0.2s;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
        transform: translateY(30px);
    }
}
.validation-summary {
    &.validation-summary-valid {
        display: none;
    }

    padding: 14px 18px;
    margin: 0;

    &-errors {
        background-color: #bd2d2d;
        .wt-semi();
        font-size: 1.5rem;
        color: white;

        &::before {
            font-family: "fontello";
            font-weight: 900;
            color: white;
            content: '\e800';
            font-size: 2rem;
            position: absolute;
        }
    }

    ul {
        @media @is-desktop {
            margin-left: 30px;
        }
    }
}