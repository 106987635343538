.c-message {
    padding: 1.5rem;
    background-color: @background-list-grey;
    border-radius: @border-radius-small;
    color: @font-color-darker;
    font-size: 1.5rem;

    &__header {
        color: inherit;
        font-size: 1.7rem;
        margin: 0;
        margin-bottom: 1.5rem;
        font-weight: 600;
    }

    p {
        margin: 0;
        & + p {
            margin-top: 0.5em;
        }
    }

    a {
        color: @mp-blue;
    }

    // modifiers
    &--positive {
        background-color: #fcfff5;
        color: #2c662d;
    }
}
