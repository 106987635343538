.o-panel {
    display: block;
    padding: 10px;

    &--large-padding {
        padding: 20px;
    }

    &--grey {
        background: @background-grey-lighter;
    }

    &--white {
        background: white;
    }

    &--rounded {
        border-radius: @border-radius;
    }
}