body {
    margin-bottom: var(--body-bottom-margin);

    .noscroll & {
        overflow: hidden;
        position: fixed;
        width: 100%;
    }
    &.max-vertical-space {
        &.o-layout--mobile {
            margin-bottom: 0;
            --body-bottom-margin: 40px;
            .contentWrapper {
                // remove the 79 px
                --height: calc(
                    ~"var(--vh) - var(--header-height) - var(--breadcrumb-height)"
                );
                min-height: var(--height);
            }
            #header {
                .avatar,
                .organization-logo {
                    display: none;
                }
            }
        }

        // move the mobile-navigation out of view
        .site-navigation-wrapper {
            transform: translateY(150%);
        }
    }

    &.disable-default-bottom-nav {
        --body-bottom-margin: 65px;

        // move the mobile-navigation out of view
        .site-navigation-wrapper {
            transform: translateY(150%);
        }
    }
}
